/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Center Stock In
 * Description: Center Stock In Form Content
 * Date: 31/03/2022
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import Countries from '../../../json/Countries.json';
import CommonApiService from '../../../services/CommonApiService';
import GenericForm from '../generic/FormInfo';
import ManufacturerForm from '../manufacturer/FormInfo';
import { useCurrentStockEntry } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { auth } = useAuth();
    const layer = auth?.employee_info?.layer_id;

    // Stock In Details
    const [centerWarehouseData, setCenterWarehouseData] = useState();
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    const [checked, setChecked] = useState('');

    const [stockInDetails, setStockInDetails] = useState([]);
    const [stockInDetailsShowModal, setStockInDetailsShowModal] = useState(false); // For Edit
    const [stockInDetailsRowData, setStockInDetailsRowData] = useState({});
    const [poQty, setPoQty] = useState('');
    const [zIndexValue, setZIndexValue] = useState('0');
    const [zIndexValue2, setZIndexValue2] = useState('0');
    const [zIndexValue3, setZIndexValue3] = useState('0');

    const {
        register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                entry_date: editData?.entry_date ? new Date(editData.entry_date) : new Date(),
                po_date: editData?.po_date && new Date(editData.po_date),
                warehouse_id: editData && {
                    label: editData?.warehouse?.name,
                    value: editData?.warehouse_id,
                },
                partner_id:
                    (auth?.employee_info?.partner_id && {
                        label: auth?.employee_info?.partner_name,
                        value: auth?.employee_info?.partner_id,
                    }) ||
                    (editData?.partner_id && {
                        label: editData?.partner_info?.name,
                        value: editData?.partner_id,
                    }),
                facility_id:
                    (auth?.employee_info?.facility_id && {
                        label: auth?.employee_info?.facility_name,
                        value: auth?.employee_info?.facility_id,
                    }) ||
                    (editData?.facility_id && {
                        label: editData?.facility_info?.name,
                        value: editData?.facility_id,
                    }),
                dispensary_id:
                    (auth?.employee_info?.dispensary_id && {
                        label: auth?.employee_info?.dispensary_name,
                        value: auth?.employee_info?.dispensary_id,
                    }) ||
                    (editData?.dispensary_id && {
                        label: editData?.dispensary_info?.name,
                        value: editData?.dispensary_id,
                    }),
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            entry_date: editData?.entry_date ? new Date(editData.entry_date) : new Date(),
            po_date: editData?.po_date && new Date(editData.po_date),
            warehouse_id: editData && {
                label: editData?.warehouse?.name,
                value: editData?.warehouse_id,
            },
            partner_id:
                (auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                }) ||
                (editData?.partner_id && {
                    label: editData?.partner_info?.name,
                    value: editData?.partner_id,
                }),
            facility_id:
                (auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                }) ||
                (editData?.facility_id && {
                    label: editData?.facility_info?.name,
                    value: editData?.facility_id,
                }),
            dispensary_id:
                (auth?.employee_info?.dispensary_id && {
                    label: auth?.employee_info?.dispensary_name,
                    value: auth?.employee_info?.dispensary_id,
                }) ||
                (editData?.dispensary_id && {
                    label: editData?.dispensary_info?.name,
                    value: editData?.dispensary_id,
                }),
        });
    }, [editData]);

    useEffect(() => {
        if (editData?.warehouse_id) {
            setChecked('checked');
        }
    }, [editData]);

    useEffect(() => {
        setValue('stockInDetails', stockInDetails);
    }, [stockInDetails]);

    // Stock In Details
    const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
        reset: reset3,
        getValues: getValues3,
        setValue: setValue3,
        setError: setError3,
        clearErrors: clearErrors3,
        control: control3,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });
    // Reset default values of Stockin Details

    useEffect(() => {
        reset3({
            ...stockInDetailsRowData.row,
            drug_id: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.drug_info?.code_no
                    ? `${stockInDetailsRowData?.row?.drug_info?.code_no} ${stockInDetailsRowData?.row?.drug_info?.name} ${stockInDetailsRowData?.row?.drug_info.presentation_type?.name}
                ${stockInDetailsRowData?.row?.drug_info?.presentation_strength?.name}`
                    : stockInDetailsRowData?.row?.drug_info?.name,
                value: stockInDetailsRowData?.row?.drug_id,
            },
            batch_no: stockInDetailsRowData?.row?.batch_no,

            mfg_date: stockInDetailsRowData.row
                ? new Date(stockInDetailsRowData?.row?.mfg_date)
                : null,
            expire_date: stockInDetailsRowData.row
                ? new Date(stockInDetailsRowData?.row?.expire_date)
                : null,

            manufacturer_id: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.manufacturer?.name,
                value: stockInDetailsRowData?.row?.manufacturer_id,
            },
            pack_type: stockInDetailsRowData.row && {
                label: stockInDetailsRowData?.row?.pack_type,
                value: stockInDetailsRowData?.row?.pack_type,
            },
            country_id:
                stockInDetailsRowData.row &&
                Countries.filter((el) => el.id == stockInDetailsRowData?.row?.country_id).map(
                    (ele) => ({
                        value: ele.id,
                        label: ele.name,
                    })
                ),
            pack_no: stockInDetailsRowData.row && stockInDetailsRowData?.row?.pack_no,
            pack_size: stockInDetailsRowData.row && stockInDetailsRowData?.row?.pack_size,
            invoice_qty: stockInDetailsRowData.row && stockInDetailsRowData?.row?.invoice_qty,
            receive_qty: stockInDetailsRowData.row && stockInDetailsRowData?.row?.receive_qty,
            rejected_qty: stockInDetailsRowData.row && stockInDetailsRowData?.row?.rejected_qty,
            rejected_reason:
                stockInDetailsRowData.row && stockInDetailsRowData?.row?.rejected_reason,
            currency_type: stockInDetailsRowData.row && {
                label:
                    stockInDetailsRowData?.row?.currency_type === 'bdt'
                        ? 'BDT'
                        : stockInDetailsRowData?.row?.currency_type === 'usd'
                        ? 'USD'
                        : stockInDetailsRowData?.row?.currency_type === 'euro'
                        ? 'EURO'
                        : '',
                value: stockInDetailsRowData?.row?.currency_type,
            },
            currency_amount:
                stockInDetailsRowData.row && stockInDetailsRowData?.row?.currency_amount,
            currency_rate: stockInDetailsRowData.row && stockInDetailsRowData?.row?.currency_rate,
            unit_price: stockInDetailsRowData.row && stockInDetailsRowData?.row?.unit_price,
        });
    }, [stockInDetailsRowData]);

    // Dependancy
    const {
        drugInfo,
        getDrugInfo,
        getSuppliers,
        centerWarehouse,
        getCenterWarehouse,
        manufacturers,
        getManufacturers,
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();
    const { addCurrentStockEntry, editCurrentStockEntry, setShow, setRowData, isSubmitted } =
        useCurrentStockEntry();

    // Local State
    const [manufacturersData, setManufacturersData] = useState();
    const [drugAllData, setAllDrugData] = useState();
    const [countryData, setCountryData] = useState();
    const [showTable, setShowTable] = useState(false);
    const [amountInTk, setAmountInTk] = useState(0);
    const [receiveQty, setReceiveQty] = useState();
    const [rejectQty, setRejectQty] = useState();

    const dataList = useCallback(() => {
        getDrugInfo(); // DrugInfo
        getSuppliers(); // Suppliers
        getCenterWarehouse(); // Warehouse
        getManufacturers(); // Manufacturer
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Center Warehouse
        setCenterWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Manufacturer
        setManufacturersData(
            manufacturers?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Drug Info
        setAllDrugData(
            drugInfo?.map((el) => ({
                ...el,
                value: el.drug_id,
                label: el.drug_name,
                purchase_qty: el.purchase_qty,
            }))
        );
        // Countries
        setCountryData(
            Countries?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [
        partner,
        facilityByPartner,
        dispensaryByFacility,
        centerWarehouse,
        manufacturers,
        drugInfo,
    ]);

    const stockInDetailsAppendHandler = (data) => {
        const detailFormData = {
            ...data,
            drug_info: {
                id: data.drug_id?.value,
                name: data.drug_id?.label,
            },
            mfg_date: data?.mfg_date && data?.mfg_date?.toLocaleDateString('sv-SE'),
            expire_date: data?.expire_date && data?.expire_date?.toLocaleDateString('sv-SE'),
            drug_id: data.drug_id.value,
            manufacturer_id: data?.manufacturer_id?.value,
            manufacturer: { id: data?.manufacturer_id?.value, name: data?.manufacturer_id?.label },
            currency_type: data?.currency_type?.value,
            country_id: data?.country_id?.value,
            pack_type: data?.pack_type?.value,
            po_qty: poQty,
        };
        // setStockInDetailsData(stockInDetailsData);
        setStockInDetails([...stockInDetails, detailFormData]);
        setShowTable(true);
        reset3({
            drug_id: '',
            batch_no: '',
            mfg_date: '',
            expire_date: '',
            manufacturer_id: '',
            country_id: '',
            pack_type: '',
            pack_no: '',
            pack_size: '',
            invoice_qty: '',
            receive_qty: '',
            rejected_qty: '',
            rejected_reason: '',
            currency_type: '',
            currency_amount: '',
            currency_rate: '',
            unit_price: '',
        });
        setAmountInTk('');
        setPoQty('');
    };

    const stockInDetailsRemoveHandler = (did) => {
        const updatedStockInDetails =
            stockInDetails && stockInDetails.filter((m) => m.drug_id !== did);
        setStockInDetails(updatedStockInDetails);
    };

    // Stock In Details Edit: Row Data
    useEffect(() => {
        if (editData) {
            setStockInDetails(...stockInDetails, editData.stock_in_detail);
        }
    }, [editData]);

    // Stock In Details Edit: Model show with edit data
    const stockInDetailsModalHandler = (row, i) => {
        setStockInDetailsShowModal(true);
        setStockInDetailsRowData({ row, i }); // Set Transport Row Data
    };

    const currencyData = [
        { label: 'BDT', value: 'bdt' },
        { label: 'USD', value: 'usd' },
        { label: 'EURO', value: 'euro' },
    ];

    const unitTypeData = [
        { label: 'Box', value: 'Box' },
        { label: 'Pack', value: 'Pack' },
        { label: 'Bottles', value: 'Bottles' },
        { label: 'Jar/Pot', value: 'Jar/Pot' },
    ];

    const packNoHandler = (packno) => {
        const packSize = getValues3('pack_size');
        const convertedInvoiceQty = packno * packSize;
        setReceiveQty(convertedInvoiceQty);
        setRejectQty('0');
        setValue3('invoice_qty', convertedInvoiceQty);
        setValue3('receive_qty', convertedInvoiceQty);
        setValue3('reject_qty', '0');
    };

    const packSizeHandler = (packsize) => {
        const packNo = getValues3('pack_no');
        const convertedInvoiceQty = packsize * packNo;
        setReceiveQty(convertedInvoiceQty);
        setRejectQty('0');
        setValue3('invoice_qty', convertedInvoiceQty);
        setValue3('receive_qty', convertedInvoiceQty);
        setValue3('rejected_qty', '0');
    };

    const getReceiveQty = getValues3('receive_qty');

    const receiveQtyHandler = (value) => {
        const invqty = getValues3('invoice_qty');
        const convertedQty = invqty - value;

        if (value > invqty) {
            setReceiveQty(getReceiveQty);
            setValue3('receive_qty', getReceiveQty);

            setRejectQty('0');
            setValue3('rejected_qty', '0');
            setError3('receive_qty', {
                type: 'custom',
                message: 'Receive qty max from invoice qty',
            });
        } else {
            setReceiveQty(value);
            setValue3('receive_qty', value);

            setRejectQty(convertedQty);
            setValue3('rejected_qty', convertedQty);

            clearErrors3('receive_qty');
        }
    };

    const rejectQtyHandler = (value) => {
        const invqty = getValues3('invoice_qty');
        const convertedQty = invqty - value;

        if (value > invqty) {
            setReceiveQty(getReceiveQty);
            setValue3('receive_qty', getReceiveQty);

            setRejectQty('0');
            setValue3('rejected_qty', '0');
            setError3('rejected_qty', {
                type: 'custom',
                message: 'Reject qty max from invoice qty',
            });
        } else {
            setReceiveQty(convertedQty);
            setValue3('receive_qty', convertedQty);

            setRejectQty(value);
            setValue3('rejected_qty', value);

            clearErrors3('rejected_qty');
        }
    };

    const currencyAmountHandler = (amount) => {
        const currencyRate = getValues3('currency_rate');
        const convertedAmount = amount * currencyRate;
        setAmountInTk(convertedAmount);
        setValue3('unit_price', convertedAmount);
    };

    const currencyRateHandler = (rate) => {
        const currencyAmount = getValues3('currency_amount');
        const convertedAmount = currencyAmount * rate;
        setAmountInTk(convertedAmount);
        setValue3('unit_price', convertedAmount);
    };

    // Stock In Details Edit: Handle Submit
    const stockInDetailsEditHandleSubmit = async (data) => {
        const rowData = {
            ...data,
            drug_info: {
                id: data.drug_id?.value,
                name: data.drug_id?.label,
            },
            mfg_date: data?.mfg_date && data?.mfg_date?.toLocaleDateString('sv-SE'),
            expire_date: data?.expire_date && data?.expire_date?.toLocaleDateString('sv-SE'),
            drug_id: data.drug_id.value,
            manufacturer_id: data?.manufacturer_id?.value,
            manufacturer: { id: data.manufacturer_id.value, name: data.manufacturer_id.label },
            currency_type: data?.currency_type?.value,
            country_id: data?.country_id.value
                ? data?.country_id.value
                : data?.country_id[0]?.value,
            pack_type: data?.pack_type?.value,
            po_qty: poQty,
        };
        const list = [...stockInDetails];
        list[stockInDetailsRowData.i] = rowData;
        setStockInDetails(list);
        setStockInDetailsShowModal(false);
        reset3({
            drug_id: '',
            batch_no: '',
            mfg_date: '',
            expire_date: '',
            manufacturer_id: '',
            country_id: '',
            pack_type: '',
            pack_no: '',
            pack_size: '',
            invoice_qty: '',
            receive_qty: '',
            rejected_qty: '',
            rejected_reason: '',
            currency_type: '',
            currency_amount: '',
            currency_rate: '',
            unit_price: '',
            unit: '',
        });
    };

    register('stockInDetails', { required: 'Stockin details is required!' });

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            entry_date: data.entry_date && data?.entry_date?.toLocaleDateString('sv-SE'),
            po_date: data.po_date && data?.po_date?.toLocaleDateString('sv-SE'),
            warehouse_id: data?.warehouse_id?.value,
            partner_id: data?.partner_id?.value,
            facility_id: data?.facility_id?.value,
            dispensary_id: data?.dispensary_id?.value,
        };
        console.log({ formData });

        if (editData) {
            editCurrentStockEntry(formData, editData.id); // Update Data
        } else {
            addCurrentStockEntry(formData); // Create Data
        }
    };

    // Form Close
    const handleClose = () => {
        setShow(false);
        setRowData('');
    };
    // Modal Close
    const modalClose = () => {
        setStockInDetailsShowModal(false);
        reset3({
            drug_id: '',
            batch_no: '',
            mfg_date: '',
            expire_date: '',
            manufacturer_id: '',
            country_id: '',
            pack_type: '',
            pack_no: '',
            pack_size: '',
            invoice_qty: '',
            receive_qty: '',
            rejected_qty: '',
            rejected_reason: '',
            currency_type: '',
            currency_amount: '',
            currency_rate: '',
            unit_price: '',
            unit: '',
        });
    };

    const drugItemHandel = (value) => {
        setPoQty(value);
    };

    // Get Facility by Partner login
    useEffect(() => {
        if (auth?.employee_info?.partner_id) {
            getFacilityByPartner(auth.employee_info.partner_id);
        }
        if (auth?.employee_info?.facility_id) {
            getDispensaryByFacility(auth.employee_info.facility_id);
        }
    }, [auth?.employee_info?.partner_id, auth?.employee_info?.facility_id]);

    const handleCentralCurrentStock = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    return (
        <Form>
            <Row className="p-3">
                <fieldset
                    className="customFieldset p-4"
                    style={{ position: 'relative', zIndex: zIndexValue }}
                >
                    {layer == 1 && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 d-flex lign-items-center">
                                    <Form.Check
                                        name="central_current_stock"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={() => {
                                            handleCentralCurrentStock();
                                        }}
                                        onChange={() => {
                                            handleCentralCurrentStock();
                                        }}
                                    />
                                    <span className="mt-1">Central Current Stock</span>
                                </Form.Group>
                            </Col>
                            <hr />
                        </Row>
                    )}
                    {((layer == 1 && checked) || layer == 2) && (
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Warehouse<span className="invalid">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="warehouse_id"
                                        id="warehouse_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.warehouse_id && 'invalidInput'
                                                }
                                                options={centerWarehouseData}
                                                onBlur={() => setZIndexValue('0')}
                                                onFocus={() => setZIndexValue('10')}
                                            />
                                        )}
                                        rules={{ required: 'Please select your warehouse.' }}
                                    />
                                    {errors?.warehouse_id && (
                                        <span className="invalid">
                                            {errors?.warehouse_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {!checked && layer != 2 && (
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Partner Agency<span className="invalid">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="partner_id"
                                        id="partner_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerData}
                                                isDisabled={auth?.employee_info?.partner_id}
                                                onBlur={async () => {
                                                    await resetField('facility_id');
                                                    await resetField('dispensary_id');
                                                    await getFacilityByPartner(
                                                        field?.value?.value
                                                            ? field?.value?.value
                                                            : 0
                                                    );
                                                    setZIndexValue('0');
                                                }}
                                                onFocus={() => setZIndexValue('10')}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />
                                    {errors?.partner_id && (
                                        <span className="invalid">
                                            {errors?.partner_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Facility Name</Form.Label>
                                    <Controller
                                        name="facility_id"
                                        id="facility_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.facility_id && 'invalidInput'
                                                }
                                                options={facilityData}
                                                isDisabled={
                                                    auth?.employee_info?.partner_id &&
                                                    auth?.employee_info?.facility_id
                                                }
                                                onBlur={async () => {
                                                    await resetField('dispensary_id');
                                                    await getDispensaryByFacility(
                                                        field?.value?.value
                                                            ? field?.value?.value
                                                            : 0
                                                    );
                                                    setZIndexValue('0');
                                                }}
                                                onFocus={() => setZIndexValue('10')}
                                            />
                                        )}
                                        // rules={{ required: 'Please select your facility.' }}
                                    />
                                    {errors?.facility_id && (
                                        <span className="invalid">
                                            {errors?.facility_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Department Name</Form.Label>
                                    <Controller
                                        name="dispensary_id"
                                        id="dispensary_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isDisabled={
                                                    auth?.employee_info?.partner_id &&
                                                    auth?.employee_info?.facility_id &&
                                                    auth?.employee_info?.dispensary_id
                                                }
                                                classNamePrefix={
                                                    errors?.dispensary_id && 'invalidInput'
                                                }
                                                options={dispensaryData}
                                                onBlur={() => setZIndexValue('0')}
                                                onFocus={() => setZIndexValue('10')}
                                            />
                                        )}
                                        // rules={{ required: 'Please select your dispensary.' }}
                                    />
                                    {errors?.dispensary_id && (
                                        <span className="invalid">
                                            {errors?.dispensary_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </fieldset>
            </Row>

            <Row className="p-3">
                <fieldset
                    className="customFieldset p-4"
                    style={{ position: 'relative', zIndex: zIndexValue2 }}
                >
                    <Row>
                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Entry Date<span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="entry_date"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            className="form-control"
                                            popperPlacement="top-end"
                                            onBlur={() => setZIndexValue2('0')}
                                            onFocus={() => setZIndexValue2('10')}
                                        />
                                    )}
                                    rules={{
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.entry_date && (
                                    <span className="invalid">{errors.entry_date?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    PO No<span className="invalid">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="po_no"
                                    id="po_no"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('po_no', {
                                        required: 'Field is required!',
                                    })}
                                    className={errors?.po_no && 'invalidInput'}
                                />
                                {errors.po_no && (
                                    <span className="invalid">{errors.po_no?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>PO Date</Form.Label>
                                <Controller
                                    control={control}
                                    name="po_date"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            closeOnScroll
                                            placeholderText="Select date"
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field?.value}
                                            className="form-control"
                                            popperPlacement="top-end"
                                            maxDate={new Date()}
                                            onBlur={() => setZIndexValue2('0')}
                                            onFocus={() => setZIndexValue2('10')}
                                        />
                                    )}
                                    rules={{
                                        // required: 'Date is required.',
                                        valueAsDate: true,
                                    }}
                                />
                                {errors.po_date && (
                                    <span className="invalid">{errors.po_date?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Waybill No</Form.Label>
                                <Form.Control
                                    name="waybill_no"
                                    id="waybill_no"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('waybill_no', {
                                        // required: 'Field is required!',
                                    })}
                                    className={errors?.waybill_no && 'invalidInput'}
                                />
                                {errors.waybill_no && (
                                    <span className="invalid">{errors.waybill_no?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
            </Row>

            <Row className="p-3">
                <fieldset
                    className="customFieldset p-4"
                    style={{ position: 'relative', zIndex: zIndexValue3 }}
                >
                    <div key={3}>
                        <Row>
                            <Col lg={11}>
                                <Row className="align-items-center">
                                    <Col lg={8}>
                                        <Form.Group className="mb-3">
                                            <div className="labelRightButton">
                                                <Form.Label className="float-start">
                                                    Item Name <span className="text-danger">*</span>
                                                </Form.Label>
                                            </div>
                                            <Row style={{ marginTop: '-10px' }}>
                                                <Col lg={12}>
                                                    <Controller
                                                        name="drug_id"
                                                        id="drug_id"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.drug_id &&
                                                                    'invalidInput'
                                                                }
                                                                options={drugAllData}
                                                                onBlur={() => {
                                                                    drugItemHandel(
                                                                        field?.value?.purchase_qty
                                                                    );
                                                                    setZIndexValue3('0');
                                                                }}
                                                                onFocus={() =>
                                                                    setZIndexValue3('10')
                                                                }
                                                            />
                                                        )}
                                                        rules={{
                                                            required: 'Please select drug item.',
                                                        }}
                                                    />
                                                    {errors3.drug_id && (
                                                        <span className="invalid">
                                                            {errors3.drug_id?.message}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Batch No. <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                name="batch_no"
                                                id="batch_no"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register3('batch_no', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors3?.batch_no && 'invalidInput'}
                                            />
                                            {errors3.batch_no && (
                                                <span className="invalid">
                                                    {errors3.batch_no?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mfg Date</Form.Label>
                                            <Controller
                                                control={control3}
                                                name="mfg_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field?.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        // maxDate={new Date()}
                                                        onBlur={() => setZIndexValue3('0')}
                                                        onFocus={() => setZIndexValue3('10')}
                                                    />
                                                )}
                                                rules={{
                                                    // required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors3.mfg_date && (
                                                <span className="invalid">
                                                    {errors3.mfg_date?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Expire Date <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Controller
                                                control={control3}
                                                name="expire_date"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        closeOnScroll
                                                        placeholderText="Select date"
                                                        dateFormat="dd-MM-yyyy"
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        className="form-control"
                                                        popperPlacement="top-end"
                                                        minDate={new Date()}
                                                        onBlur={() => setZIndexValue3('0')}
                                                        onFocus={() => setZIndexValue3('10')}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Date is required.',
                                                    valueAsDate: true,
                                                }}
                                            />
                                            {errors3.expire_date && (
                                                <span className="invalid">
                                                    {errors3.expire_date?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <div className="labelRightButton">
                                                <Form.Label className="float-start">
                                                    Manufaturer
                                                </Form.Label>
                                                <ManufacturerForm instantCreate />
                                            </div>
                                            <Row style={{ marginTop: '-10px' }}>
                                                <Col lg={12}>
                                                    <Controller
                                                        name="manufacturer_id"
                                                        id="manufacturer_id"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.manufacturer_id &&
                                                                    'invalidInput'
                                                                }
                                                                options={manufacturersData}
                                                                onFocus={() => {
                                                                    getManufacturers();
                                                                    setZIndexValue3('10');
                                                                }}
                                                                onBlur={() => setZIndexValue3('0')}
                                                            />
                                                        )}
                                                        rules={
                                                            {
                                                                // required: 'Please select Manufacturer.',
                                                            }
                                                        }
                                                    />
                                                    {errors3.manufacturer_id && (
                                                        <span className="invalid">
                                                            {errors3.manufacturer_id?.message}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Country of Manufaturer</Form.Label>
                                            <Controller
                                                name="country_id"
                                                id="country_id"
                                                control={control3}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        classNamePrefix={
                                                            errors3?.country_id && 'invalidInput'
                                                        }
                                                        options={countryData}
                                                        onBlur={() => setZIndexValue3('0')}
                                                        onFocus={() => setZIndexValue3('10')}
                                                    />
                                                )}
                                                rules={
                                                    {
                                                        // required: 'Please select country.',
                                                    }
                                                }
                                            />
                                            {errors3.country_id && (
                                                <span className="invalid">
                                                    {errors3.country_id?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Packing Unit Type{' '}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Controller
                                                name="pack_type"
                                                id="pack_type"
                                                control={control3}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        classNamePrefix={
                                                            errors3?.pack_type && 'invalidInput'
                                                        }
                                                        options={unitTypeData}
                                                        onBlur={() => setZIndexValue3('0')}
                                                        onFocus={() => setZIndexValue3('10')}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Please select your unit type.',
                                                }}
                                            />
                                            {errors3.pack_type && (
                                                <span className="invalid">
                                                    {errors3.pack_type?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                No. of Packing Unit{' '}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                name="pack_no"
                                                id="pack_no"
                                                type="number"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register3('pack_no')}
                                                className={errors3?.pack_no && 'invalidInput'}
                                                onBlur={(e) => packNoHandler(e.target.value)}
                                            />
                                            {errors3.pack_no && (
                                                <span className="invalid">
                                                    {errors3.pack_no?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                No. of Per Packing Unit{' '}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                name="pack_size"
                                                id="pack_size"
                                                type="number"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register3('pack_size')}
                                                className={errors3?.pack_size && 'invalidInput'}
                                                onBlur={(e) => packSizeHandler(e.target.value)}
                                            />
                                            {errors3.pack_size && (
                                                <span className="invalid">
                                                    {errors3.pack_size?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Current Qty <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                name="receive_qty"
                                                id="receive_qty"
                                                type="number"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register3('receive_qty', {
                                                    required: 'Field is required!',
                                                })}
                                                className={errors3?.receive_qty && 'invalidInput'}
                                                defaultValue={receiveQty}
                                                onBlur={(e) => receiveQtyHandler(e.target.value)}
                                            />
                                            {errors3.receive_qty && (
                                                <span className="invalid">
                                                    {errors3.receive_qty?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Current Rejected Qty</Form.Label>
                                            <Form.Control
                                                name="rejected_qty"
                                                id="rejected_qty"
                                                type="number"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register3('rejected_qty', {
                                                    // required: 'Field is required!',
                                                })}
                                                className={errors3?.rejected_qty && 'invalidInput'}
                                                defaultValue={rejectQty}
                                                onBlur={(e) => rejectQtyHandler(e.target.value)}
                                            />
                                            {errors3.rejected_qty && (
                                                <span className="invalid">
                                                    {errors3.rejected_qty?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Reason of Reject</Form.Label>
                                            <Form.Control
                                                name="rejected_reason"
                                                id="rejected_reason"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register3('rejected_reason', {
                                                    // required: 'Field is required!',
                                                })}
                                                className={
                                                    errors3?.rejected_reason && 'invalidInput'
                                                }
                                            />
                                            {errors3.rejected_reason && (
                                                <span className="invalid">
                                                    {errors3.rejected_reason?.message}
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    {(layer == 1 || layer == 2) && (
                                        <>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Currency{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Controller
                                                        name="currency_type"
                                                        id="currency_type"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.currency_type &&
                                                                    'invalidInput'
                                                                }
                                                                options={currencyData}
                                                                onBlur={() => setZIndexValue3('0')}
                                                                onFocus={() =>
                                                                    setZIndexValue3('10')
                                                                }
                                                            />
                                                        )}
                                                        rules={{
                                                            required:
                                                                'Please select your currency_type.',
                                                        }}
                                                    />
                                                    {errors3.currency_type && (
                                                        <span className="invalid">
                                                            {errors3.currency_type?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Item Price (Per Unit){' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="currency_amount"
                                                        id="currency_amount"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('currency_amount', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.currency_amount &&
                                                            'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            currencyAmountHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.currency_amount && (
                                                        <span className="invalid">
                                                            {errors3.currency_amount?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Exchange Rate{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="currency_rate"
                                                        id="currency_rate"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('currency_rate', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.currency_rate && 'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            currencyRateHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.currency_rate && (
                                                        <span className="invalid">
                                                            {errors3.currency_rate?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Amount in BDT (Per Unit){' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="unit_price"
                                                        id="unit_price"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        disabled
                                                        {...register3('unit_price', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.unit_price && 'invalidInput'
                                                        }
                                                        value={amountInTk}
                                                    />
                                                    {errors3.unit_price && (
                                                        <span className="invalid">
                                                            {errors3.unit_price?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </Col>
                            <Col lg={1} className="pt-3">
                                <Button
                                    className="btnSteelBlue h-100"
                                    variant="primary"
                                    // onClick={() => stockInDetailsAppendHandler()}
                                    onClick={handleSubmit3(stockInDetailsAppendHandler)}
                                >
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col>
                            <Table className="borderDashed mt-3" responsive="sm">
                                <tbody>
                                    {(editData?.stock_in_detail || showTable) && (
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Current Qty</th>
                                            <th>Reject Qty</th>
                                            <th>Unit Price</th>
                                            <th>Action</th>
                                        </tr>
                                    )}

                                    {stockInDetails?.map((el, i) => (
                                        <tr key={Math.random()}>
                                            {el.drug_info?.code_no ? (
                                                <td>
                                                    {el.drug_info?.code_no} {el.drug_info?.name}{' '}
                                                    {el.drug_info?.presentation_type?.name}{' '}
                                                    {el.drug_info?.presentation_strength?.name}
                                                </td>
                                            ) : (
                                                <td>{el.drug_info?.name}</td>
                                            )}

                                            <td>{el.receive_qty}</td>
                                            <td>{el.rejected_qty}</td>
                                            <td>{el.unit_price}</td>
                                            <td className="text-end">
                                                {editData && (
                                                    <Button
                                                        className="btn btn-light actionBtn"
                                                        variant="primary"
                                                        onClick={() =>
                                                            stockInDetailsModalHandler(el, i)
                                                        }
                                                    >
                                                        <i className="fas fa-edit" />
                                                    </Button>
                                                )}
                                                <Button
                                                    className="btn btn-light actionBtn"
                                                    variant="primary"
                                                    onClick={() =>
                                                        stockInDetailsRemoveHandler(el.drug_id)
                                                    }
                                                >
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            {errors.stockInDetails && (
                                <span className="invalid">{errors.stockInDetails?.message}</span>
                            )}
                        </Col>
                    </Row>
                </fieldset>
            </Row>
            {editData && (
                <>
                    {/* Edit stock In Details */}
                    <Modal
                        className="modalWidth80"
                        show={stockInDetailsShowModal}
                        onHide={modalClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit stock In Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form key={5}>
                                <Row>
                                    <Col lg={12}>
                                        <Row className="align-items-center">
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <div className="labelRightButton">
                                                        <Form.Label className="float-start">
                                                            Item Name{' '}
                                                            <span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <GenericForm instantCreate />
                                                    </div>
                                                    <Row style={{ marginTop: '-10px' }}>
                                                        <Col lg={12}>
                                                            <Controller
                                                                name="drug_id"
                                                                id="drug_id"
                                                                control={control3}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        classNamePrefix={
                                                                            errors3?.drug_id &&
                                                                            'invalidInput'
                                                                        }
                                                                        options={drugAllData}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        'Please select generic drug.',
                                                                }}
                                                            />
                                                            {errors3.drug_id && (
                                                                <span className="invalid">
                                                                    {errors3.drug_id?.message}
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Batch No{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="batch_no"
                                                        id="batch_no"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('batch_no', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.batch_no && 'invalidInput'
                                                        }
                                                    />
                                                    {errors3.batch_no && (
                                                        <span className="invalid">
                                                            {errors3.batch_no?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mfg Date</Form.Label>
                                                    <Controller
                                                        control={control3}
                                                        name="mfg_date"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                closeOnScroll
                                                                placeholderText="Select date"
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) =>
                                                                    field.onChange(date)
                                                                }
                                                                selected={field.value}
                                                                className="form-control"
                                                                popperPlacement="top-end"
                                                                maxDate={new Date()}
                                                            />
                                                        )}
                                                        rules={{
                                                            // required: 'Date is required.',
                                                            valueAsDate: true,
                                                        }}
                                                    />
                                                    {errors3.mfg_date && (
                                                        <span className="invalid">
                                                            {errors3.mfg_date?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Expire Date{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Controller
                                                        control={control3}
                                                        name="expire_date"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                {...field}
                                                                closeOnScroll
                                                                placeholderText="Select date"
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) =>
                                                                    field.onChange(date)
                                                                }
                                                                selected={field.value}
                                                                className="form-control"
                                                                popperPlacement="top-end"
                                                                minDate={new Date()}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: 'Date is required.',
                                                            valueAsDate: true,
                                                        }}
                                                    />
                                                    {errors3.expire_date && (
                                                        <span className="invalid">
                                                            {errors3.expire_date?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <div className="labelRightButton">
                                                        <Form.Label className="float-start">
                                                            Manufaturer
                                                        </Form.Label>
                                                        <ManufacturerForm instantCreate />
                                                    </div>
                                                    <Row style={{ marginTop: '-10px' }}>
                                                        <Col lg={12}>
                                                            <Controller
                                                                name="manufacturer_id"
                                                                id="manufacturer_id"
                                                                control={control3}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        classNamePrefix={
                                                                            errors3?.manufacturer_id &&
                                                                            'invalidInput'
                                                                        }
                                                                        options={manufacturersData}
                                                                        onFocus={() =>
                                                                            getManufacturers()
                                                                        }
                                                                    />
                                                                )}
                                                                rules={
                                                                    {
                                                                        // required:
                                                                        //     'Please select Manufacturer.',
                                                                    }
                                                                }
                                                            />
                                                            {errors3.manufacturer_id && (
                                                                <span className="invalid">
                                                                    {
                                                                        errors3.manufacturer_id
                                                                            ?.message
                                                                    }
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country of Manufaturer</Form.Label>
                                                    <Controller
                                                        name="country_id"
                                                        id="country_id"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.country_id &&
                                                                    'invalidInput'
                                                                }
                                                                options={countryData}
                                                            />
                                                        )}
                                                        rules={
                                                            {
                                                                // required: 'Please select country.',
                                                            }
                                                        }
                                                    />
                                                    {errors3.country_id && (
                                                        <span className="invalid">
                                                            {errors3.country_id?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Packing Unit Type{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Controller
                                                        name="pack_type"
                                                        id="pack_type"
                                                        control={control3}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                classNamePrefix={
                                                                    errors3?.pack_type &&
                                                                    'invalidInput'
                                                                }
                                                                options={unitTypeData}
                                                            />
                                                        )}
                                                        rules={{
                                                            required:
                                                                'Please select your unit type.',
                                                        }}
                                                    />
                                                    {errors3.pack_type && (
                                                        <span className="invalid">
                                                            {errors3.pack_type?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        No. of Packing Unit{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pack_no"
                                                        id="pack_no"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('pack_no')}
                                                        className={
                                                            errors3?.pack_no && 'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            packNoHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.pack_no && (
                                                        <span className="invalid">
                                                            {errors3.pack_no?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        No. of Per Packing Unit{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="pack_size"
                                                        id="pack_size"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('pack_size', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.pack_size && 'invalidInput'
                                                        }
                                                        onBlur={(e) =>
                                                            packSizeHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.pack_size && (
                                                        <span className="invalid">
                                                            {errors3.pack_size?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>

                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Current Qty{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="receive_qty"
                                                        id="receive_qty"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('receive_qty', {
                                                            required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.receive_qty && 'invalidInput'
                                                        }
                                                        defaultValue={receiveQty}
                                                        onBlur={(e) =>
                                                            receiveQtyHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.receive_qty && (
                                                        <span className="invalid">
                                                            {errors3.receive_qty?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>current Rejected Qty</Form.Label>
                                                    <Form.Control
                                                        name="rejected_qty"
                                                        id="rejected_qty"
                                                        type="number"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('rejected_qty', {
                                                            // required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.rejected_qty && 'invalidInput'
                                                        }
                                                        defaultValue={rejectQty}
                                                        onBlur={(e) =>
                                                            rejectQtyHandler(e.target.value)
                                                        }
                                                    />
                                                    {errors3.rejected_qty && (
                                                        <span className="invalid">
                                                            {errors3.rejected_qty?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group>
                                                    <Form.Label>Reason of Reject</Form.Label>
                                                    <Form.Control
                                                        name="rejected_reason"
                                                        id="rejected_reason"
                                                        type="text"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        {...register3('rejected_reason', {
                                                            // required: 'Field is required!',
                                                        })}
                                                        className={
                                                            errors3?.rejected_reason &&
                                                            'invalidInput'
                                                        }
                                                    />
                                                    {errors3.rejected_reason && (
                                                        <span className="invalid">
                                                            {errors3.rejected_reason?.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            {(layer == 1 || layer == 2) && (
                                                <>
                                                    <Col lg={3}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Currency{' '}
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </Form.Label>
                                                            <Controller
                                                                name="currency_type"
                                                                id="currency_type"
                                                                control={control3}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        classNamePrefix={
                                                                            errors3?.currency_type &&
                                                                            'invalidInput'
                                                                        }
                                                                        options={currencyData}
                                                                    />
                                                                )}
                                                                rules={{
                                                                    required:
                                                                        'Please select your currency_type.',
                                                                }}
                                                            />
                                                            {errors3.currency_type && (
                                                                <span className="invalid">
                                                                    {errors3.currency_type?.message}
                                                                </span>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Item Price (Per Unit){' '}
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="currency_amount"
                                                                id="currency_amount"
                                                                type="number"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                {...register3('currency_amount', {
                                                                    required: 'Field is required!',
                                                                })}
                                                                className={
                                                                    errors3?.currency_amount &&
                                                                    'invalidInput'
                                                                }
                                                                onBlur={(e) =>
                                                                    currencyAmountHandler(
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                            {errors3.currency_amount && (
                                                                <span className="invalid">
                                                                    {
                                                                        errors3.currency_amount
                                                                            ?.message
                                                                    }
                                                                </span>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Exchange Rate{' '}
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="currency_rate"
                                                                id="currency_rate"
                                                                type="number"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                {...register3('currency_rate', {
                                                                    required: 'Field is required!',
                                                                })}
                                                                className={
                                                                    errors3?.currency_rate &&
                                                                    'invalidInput'
                                                                }
                                                                onBlur={(e) =>
                                                                    currencyRateHandler(
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                            {errors3.currency_rate && (
                                                                <span className="invalid">
                                                                    {errors3.currency_rate?.message}
                                                                </span>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Amount in BDT (Per Unit)
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="unit_price"
                                                                id="unit_price"
                                                                type="number"
                                                                placeholder=""
                                                                autoComplete="off"
                                                                disabled
                                                                {...register3('unit_price', {
                                                                    required: 'Field is required!',
                                                                })}
                                                                className={
                                                                    errors3?.unit_price &&
                                                                    'invalidInput'
                                                                }
                                                                value={amountInTk}
                                                            />
                                                            {errors3.unit_price && (
                                                                <span className="invalid">
                                                                    {errors3.unit_price?.message}
                                                                </span>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>

                                <Modal.Footer className="borderTop0 pe-0">
                                    <Button
                                        className="btnCancel"
                                        variant="secondary"
                                        onClick={modalClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        className="btnSuccess"
                                        onClick={handleSubmit3(stockInDetailsEditHandleSubmit)}
                                        type="button"
                                        variant="success"
                                    >
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </>
            )}
            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit(formSubmitHandler)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                    disabled={isSubmitted}
                >
                    {editData !== '' ? 'Update' : 'Submit'}
                </Button>
            </div>
        </Form>
    );
}
