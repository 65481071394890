/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useD2Distribution } from './ContextAPI';
import RequisitionTable from './RequisitionTable';

export default function FormInfo() {
    const { setFormShow, rowData, receiveData, setReceiveData, receiveList } = useD2Distribution();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [transports, setTransports] = useState([]);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.d2_details_id = el.d2_details_id;
            itemdata.central_warehouse_qty = el.central_warehouse_qty;
            itemdata.batch_no = el.batch_no;
            itemdata.category_id = el.category_id;
            itemdata.requested_qty = el.requested_qty;
            itemdata.approved_qty = el.approved_qty;
            itemdata.final_approved_qty = el.final_approved_qty;
            itemdata.supplied_qty =
                Number(el.central_warehouse_qty) > Number(el.requested_qty)
                    ? el.final_approved_qty
                    : el.central_warehouse_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            // const arrOfObj = toArray(list);
            // setReceiveList(arrOfObj);
            return receiveData;
        });
    }, [rowData.records]);

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Transports
    // const {
    //     register: register2,
    //     formState: { errors: errors2 },
    //     handleSubmit: handleSubmit2,
    //     reset: reset2,
    // } = useForm({
    //     mode: 'onTouched',
    //     criteriaMode: 'firstError',
    //     reValidateMode: 'onBlur',
    // });

    // Transporter Info
    // const transportAppendHandler = (data) => {
    //     setTransports([...transports, data]);
    //     reset2({
    //         transporter: '',
    //         vehicle_no: '',
    //         driver: '',
    //     });
    // };

    // const transportRemoveHandler = (vehicle_no) => {
    //     const updatedTransport =
    //         transports && transports.filter((m) => m.vehicle_no !== vehicle_no);
    //     setTransports(updatedTransport);
    // };

    // Set default values
    useEffect(() => {
        if (!isEmpty(receiveList)) {
            setValue('requisition', receiveList);
        }
    }, [receiveList]);

    // Set default values
    // useEffect(() => {
    //     setValue('transports', transports);
    // }, [transports]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <RequisitionTable
                    data={rowData?.records?.filter((el) => el.category_id == item.id)}
                />
            </Tab>
        ));

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            distribution_type_id: rowData.d2_master_id,
            distribution_type: 'd2',
            partner_id: rowData.partner_id,
            central_warehouse_id: rowData.central_warehouse_id,
            transports: [
                { transporter: data.transporter, vehicle_no: data.vehicle_no, driver: data.driver },
            ],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.D2SaveCentralStockOut(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response?.data?.status === 'error') {
                setIsSubmitted(false);
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setIsSubmitted(true);
                ToastifyService.updateNotify('D2 Approve Successfull');
                setFormShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setFormShow(false);
    };

    register('requisition', { required: 'Requisition quantity is required!' });
    // register('transports', { required: 'Transports is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>D2 Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <fieldset className="customFieldset p-4 mb-4">
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Transporter</Form.Label>
                                <Form.Control
                                    name="transporter"
                                    id="transporter"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('transporter', {
                                        required: 'Field is required!',
                                    })}
                                    className={errors?.transporter && 'invalidInput'}
                                />
                                {errors.transporter && (
                                    <span className="invalid">{errors.transporter?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="pe-1">
                            <Form.Group className="mb-3">
                                <Form.Label className="pe-5">Vehicle No</Form.Label>
                                <Form.Control
                                    name="vehicle_no"
                                    id="vehicle_no"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('vehicle_no', {
                                        required: 'Field is required!',
                                    })}
                                    className={errors?.vehicle_no && 'invalidInput'}
                                />
                                {errors.vehicle_no && (
                                    <span className="invalid">{errors.vehicle_no?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4} className="pe-1">
                            <Form.Group className="mb-3">
                                <Form.Label className="pe-5">Driver</Form.Label>
                                <Form.Control
                                    name="driver"
                                    id="driver"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register('driver', {
                                        required: 'Field is required!',
                                    })}
                                    className={errors?.driver && 'invalidInput'}
                                />
                                {errors.driver && (
                                    <span className="invalid">{errors.driver?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        {/* <Col lg={1} className="p-0 pt-1">
                            <Button
                                className="btnSteelBlue mt-3"
                                variant="primary"
                                onClick={handleSubmit2(transportAppendHandler)}
                            >
                                Add
                            </Button>
                        </Col> */}
                    </Row>

                    {/* <Row>
                        <Col lg={12}>
                            <Table className="borderDashed" responsive="sm">
                                <tbody>
                                    {transports.map((item) => (
                                        <tr key={Math.random()}>
                                            <td>{item.transporter}</td>
                                            <td>{item.vehicle_no}</td>
                                            <td>{item.driver}</td>
                                            <td className="text-end">
                                                <Button
                                                    className="btn btn-light actionBtn"
                                                    variant="primary"
                                                    onClick={() =>
                                                        transportRemoveHandler(item.vehicle_no)
                                                    }
                                                >
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {errors.transports && (
                                <span className="invalid">{errors.transports?.message}</span>
                            )}
                        </Col>
                    </Row> */}
                </fieldset>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>

                {errors.requisition && (
                    <span className="invalid">{errors.requisition?.message}</span>
                )}

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Approve
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}
