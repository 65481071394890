/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Current Stock
 * Description: Index for Current Stock
 * Date: 13/07/2022
 */

import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useCurrentStock } from './ContextAPI';
import DataTable from './DataTable';
import PrintIndex from './pdf/PrintIndex';

export default function Index() {
    const { drugCategory, getDrugCategory } = CommonApiService();
    const { data, getAllData, showPdf, setShowPdf } = useCurrentStock();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);

    // console.log({ rowData });

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const handlePrintPreview = () => {
        setShowPdf(true);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item, index) => (
            <Tab key={item.id} eventKey={index} title={item.name}>
                <div className="dataTableHeaderNone">
                    <DataTable data={data?.filter((el) => el.category_id == item.id)} />
                </div>
            </Tab>
        ));

    return showPdf ? (
        <PrintIndex />
    ) : (
        <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <h6>Partner Current Stock</h6>
                <Button className="btn btn-dark me-2" onClick={handlePrintPreview}>
                    Print Preview
                </Button>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Col>
                </Row>

                <div className="formFooter">
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className="btnSuccess"
                        disabled={page === pageLength}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}
