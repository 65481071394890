/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
// eslint-disable-next-line import/prefer-default-export
export const getCategoryNameByID = (allCategory = [], _data = {}) => {
    // eslint-disable-next-line eqeqeq
    const found = allCategory.find((element) => element.id == _data.category_id);
    return found;
};

// eslint-disable-next-line arrow-body-style
export const getMonthName = (rowData) => {
    return rowData?.month == 1
        ? 'January'
        : rowData?.month == 2
        ? 'February'
        : rowData?.month == 3
        ? 'March'
        : rowData?.month == 4
        ? 'April'
        : rowData?.month == 5
        ? 'May'
        : rowData?.month == 6
        ? 'June'
        : rowData?.month == 7
        ? 'July'
        : rowData?.month == 8
        ? 'August'
        : rowData?.month == 9
        ? 'September'
        : rowData?.month == 10
        ? 'October'
        : rowData?.month == 11
        ? 'November'
        : rowData?.month == 12
        ? 'December'
        : '';
};

// eslint-disable-next-line arrow-body-style
/* eslint-disable eqeqeq */
// eslint-disable-next-line import/prefer-default-export
export const userTableFilteredByOrganigation = (customfilter, item) => {
    if (
        customfilter?.organization.value == item?.organization &&
        (customfilter?.organization.value == '1' || customfilter?.organization.value == '2')
    ) {
        return true;
    }

    if (customfilter?.organization.value == '3') {
        if (customfilter?.partner_id?.value) {
            // eslint-disable-next-line eqeqeq
            if (customfilter?.partner_id?.value == item?.partner_id) {
                return true;
            }
            return false;
        }
        if (item?.partner_id != '0' && item?.partner_id != null) {
            return true;
        }
    }
    if (customfilter?.organization.value === '4') {
        if (customfilter?.facility_id?.value) {
            // eslint-disable-next-line eqeqeq
            if (customfilter?.facility_id?.value == item?.facility_id) {
                return true;
            }

            return false;
        }
        if (customfilter?.partner_id?.value) {
            // eslint-disable-next-line eqeqeq
            if (
                customfilter?.partner_id?.value == item?.partner_id &&
                item?.facility_id != '0' &&
                item?.facility_id != null
            ) {
                return true;
            }
            return false;
        }

        if (item?.facility_id != '0' && item?.facility_id != null) {
            return true;
        }
    }

    if (customfilter?.organization.value === '5') {
        if (customfilter?.partner_id?.value) {
            // eslint-disable-next-line eqeqeq
            if (customfilter?.partner_id?.value == item?.partner_id) {
                if (customfilter?.dispensary_id?.value) {
                    // eslint-disable-next-line eqeqeq
                    if (customfilter?.dispensary_id?.value == item?.dispensary_id) {
                        return true;
                    }
                    return false;
                }
                if (customfilter?.facility_id?.value && customfilter?.dispensary_id?.value) {
                    // eslint-disable-next-line eqeqeq
                    if (customfilter?.facility_id?.value == item?.facility_id) {
                        return true;
                    }
                    // eslint-disable-next-line eqeqeq
                    if (customfilter?.dispensary_id?.value == item?.dispensary_id) {
                        return true;
                    }

                    return false;
                }

                if (customfilter?.facility_id?.value) {
                    // eslint-disable-next-line eqeqeq
                    if (customfilter?.facility_id?.value == item?.facility_id) {
                        return true;
                    }

                    return false;
                }

                return true;
            }
            return false;
        }

        if (item?.dispensary_id != '0' && item?.dispensary_id != null) {
            return true;
        }
        return false;
    }

    return false;
};

// eslint-disable-next-line default-param-last
export const checkMonthValidation_requisition = (allData = [], month_no) => {
    // eslint-disable-next-line eqeqeq
    const found = allData.find((element) => element.month == month_no);
    return !found;
};

export function convertArrayOfObjectsToCSV4d2(search_column, rowData, partner, drugCategory) {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(search_column);
    const values = Object.values(search_column);
    result = '';
    result += `Partner Name , Month , Year
    ${partner} , ${getMonthName(rowData)} , ${rowData?.year} \n\n\n `;
    result += `SL ,Drug Category , ${values.join(columnDelimiter)}`;
    result += lineDelimiter;

    rowData?.records.sort((a, b) => a.category_id - b.category_id);

    rowData?.records.forEach((item, k) => {
        result += `${k + 1} ,"${getCategoryNameByID(drugCategory, item)?.name}",`;
        let ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;
            if (key.includes('.')) {
                const subArr = key.split('.');
                const subArrString =
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                    item[subArr[0]]?.[subArr[1]];

                result += `"${subArrString}"`;
            } else {
                console.log('item', item);
                result += `"${item[key] ? item[key]?.replaceAll('#', '') : ''}"`;
            }
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;
}

export function convertArrayOfObjectsToCSVd1(search_column, rowData, partner, drugCategory) {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(search_column);
    const values = Object.values(search_column);
    result = '';
    result += `Partner Name, Facility Name ,${getMonthName(rowData) ? 'Month ,' : ''} ${
        rowData?.year ? 'Year' : ''
    }  
    ${partner} ,${rowData?.facility_name} , ${getMonthName(rowData)} , ${
        rowData?.year || ''
    } \n\n\n `;
    result += `SL ,Drug Category , ${values.join(columnDelimiter)}`;
    result += lineDelimiter;

    rowData?.records.sort((a, b) => a.category_id - b.category_id);

    rowData?.records.forEach((item, k) => {
        result += `${k + 1} ,"${getCategoryNameByID(drugCategory, item)?.name}",`;
        let ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;
            if (key.includes('.')) {
                const subArr = key.split('.');
                const subArrString =
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                    item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                    item[subArr[0]]?.[subArr[1]];

                result += `"${subArrString}"`;
            } else {
                result += `"${item[key]}"`;
            }
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;
}
