/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request DataTable
 * Description: Dispensary Internal Request DataTable
 * Date: 09/06/2022
 */
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useInternalRequest } from './ContextAPI';
// import SweetAlertService from '../../../services/SweetAlertService';
// import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';

export default function DataTable() {
    const { internalRequests, getAllData, setShowModal, setRowData } = useInternalRequest();
    // const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const showHander = (row) => {
        setShowModal(true);
        setRowData(row);
    };

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
        },
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
        },
        {
            name: 'Dispensary',
            selector: (row) => row.dispensary_name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            format: (row) => moment(row.date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Request Mode',
            selector: (row) => row.request_mode,
            sortable: true,
            cell: (row) =>
                row.request_mode == 'online' ? (
                    <Badge className="badgeActive" bg="success">
                        Online
                    </Badge>
                ) : row.request_mode == 'offline' ? (
                    <Badge className="badgeInactive" bg="error">
                        Offline
                    </Badge>
                ) : (
                    ''
                ),
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Approved
                    </Badge>
                ) : row.status == 2 ? (
                    <Badge className="badgeInactive" bg="error">
                        Rejected
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="error">
                        Pending
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    className="btn btn-light actionBtn"
                    variant="primary"
                    onClick={() => showHander(row)}
                >
                    {layout}
                </Button>
            ),
        },
    ];

    return useDataTable({ columns, data: internalRequests });
}
