/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Hook: DataTable
 * Description: Hook: DataTable
 * Date: 15/03/2022
 */

import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { getCategoryNameByID, getMonthName, userTableFilteredByOrganigation } from '../Utils';
import Loader from '../components/Loader';
import CustomFilterUserInfo from '../components/userManagement/employeeInfo/CustomFilterUserInfo';

function FilterComponent({ filterText, onFilter, onClear, showSearchClear }) {
    return (
        <div className="d-flex searchBox">
            <Form.Control
                type="text"
                placeholder="Filter Table"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <div className="search-icon">
                <i className="fas fa-search" />
            </div>

            {showSearchClear && (
                <Button className="btn btn-light searchClearBtn" type="button" onClick={onClear}>
                    x
                </Button>
            )}
        </div>
    );
}

const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
};

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
    </div>
));

export default function useDataTable(tableData) {
    const {
        columns,
        data,
        pagination,
        search_column,
        expandableRows,
        expandedComponent,
        conditionalRowStyles,
        cellPadding,
        borderWidth,
        allData,
        exportCsvAll,
        allcategory,
        rowData,
        partner,
        is_DownloadButtonEnable,
        showFilterOrganization
    } = tableData;

    const searchColumn = search_column || {};
    const isDownloadButtonEnable = is_DownloadButtonEnable || 0;
    const [filterText, setFilterText] = useState('');
    const [customfilter, setcustomFilter] = useState('');
    const [pending, setPending] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [showSearchClear, setShowSearchClear] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!isEmpty(data)) {
            setDataList(data);
            setPending(false);
        }
        
        const timeout = setTimeout(() => {
            if (data !== null && data !== undefined) {
                setPending(false);
            }

        }, 1000);
        return () => clearTimeout(timeout);
    }, [data]);
    // Start Export CSV
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(searchColumn);
        const values = Object.values(searchColumn);
        result = '';
        if (exportCsvAll) {
            result += `Partner Name, Facility Name , Month , Year
            ${partner[0]?.partner?.name} , ${rowData?.facility_name} , ${getMonthName(rowData)} , ${
                rowData?.year
            } \n\n\n `;
            result += `SL ,Drug Category , ${values.join(columnDelimiter)}`;
            result += lineDelimiter;

            allData.sort((a, b) => a.category_id - b.category_id);

            allData.forEach((item, k) => {
                result += `${k + 1} ,"${getCategoryNameByID(allcategory, item)?.name}",`;
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0) result += columnDelimiter;
                    if (key.includes('.')) {
                        const subArr = key.split('.');
                        const subArrString =
                            item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                            item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                            item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                            item[subArr[0]]?.[subArr[1]];

                        result += `"${subArrString}"`;
                    } else {
                        result += `"${item[key].replaceAll('#', '')}"`;
                    }
                    // eslint-disable-next-line no-plusplus
                    ctr++;
                });
                result += lineDelimiter;
            });
        } else {
            result += values.join(columnDelimiter);
            result += lineDelimiter;
            array.forEach((item) => {
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0) result += columnDelimiter;
                    if (key.includes('.')) {
                        const subArr = key.split('.');
                        const subArrString =
                            item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                            item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                            item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                            item[subArr[0]]?.[subArr[1]];

                        result += `"${subArrString}"`;
                    } else {
                        result += `"${item[key]}"`;
                    }
                    // eslint-disable-next-line no-plusplus
                    ctr++;
                });
                result += lineDelimiter;
            });
        }
        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = 'export.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

  // const isDownloadCsv = Object.keys(searchColumn).length;

    function Export({ onExport }) {
      //  console.log(`isDownloadButtonEnable`, isDownloadButtonEnable)
        return (
           // isDownloadCsv > 0 &&

            isDownloadButtonEnable > 0 && (
                <Button
                    className="btn btn-light downloadCsvBtn"
                    onClick={(e) => onExport(e.target.value)}
                >
                    <i className="fa fa-download" />
                </Button>
            )
        );
    }
    // End Export CSV

    // Start Filter Text
    const filteredItems = dataList.filter((item) => {
        if (customfilter?.organization) {
            // eslint-disable-next-line eqeqeq
            return userTableFilteredByOrganigation(customfilter, item?.employee || item);
        // eslint-disable-next-line no-else-return
        } else {
            const searchResult = Object.keys(searchColumn).map((el) => {
                // If relational data
                if (el.includes('.')) {
                    const subArr = el.split('.');
                    const subArrString =
                        item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]]?.[subArr[4]] ??
                        item[subArr[0]]?.[subArr[1]]?.[subArr[2]]?.[subArr[3]] ??
                        item[subArr[0]]?.[subArr[1]]?.[subArr[2]] ??
                        item[subArr[0]]?.[subArr[1]];

                    return (
                        subArrString &&
                        subArrString.toString().toLowerCase().includes(filterText.toLowerCase())
                    );
                }
                // Else
                return item[el] && item[el].toString().toLowerCase().includes(filterText.toLowerCase());
            });
            return !!searchResult.includes(true);
        }
    });
    useEffect(() => {
        if (filterText === '') {
            setShowSearchClear(false);
        }
    }, [filterText]);

    // End Filter Text
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setcustomFilter('')
                setFilterText('');
                // setPending(true);
                setShowSearchClear(false);
            }
        };
        const handleClose = () => {
            setShow(false);
        };
        const handleShow = () => {
            setcustomFilter('')
            setShow(true);
        };
        return (
            <Row style={{ display: 'contents' }}>
                <Col lg={3} className="p-0 mb-4">
                    <FilterComponent
                        onFilter={(e) => {
                            setcustomFilter('')
                            setShowSearchClear(true);
                            setFilterText(e.target.value);
                        }}
                        onClear={handleClear}
                        filterText={filterText}
                        showSearchClear={showSearchClear}
                    />
                </Col>
                {
                    showFilterOrganization &&
                    <Col className="mb-4 text-end">
                        <Button variant="primary" onClick={handleShow}>
                            Filter by Organization
                        </Button>
                        {
                            customfilter !== '' && 
                            <Button variant="secoundery" type='button' onClick={()=>  setcustomFilter('')}>
                                x
                            </Button>
                        }
                    </Col>
                }

                {
                  isDownloadButtonEnable > 0 && 
                    <Col className="mb-4 text-end downloadCSV">
                        <Export onExport={() => downloadCSV(dataList)} />
                    </Col>
                }

                <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Filter by Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={12}>
                                <CustomFilterUserInfo setcustomFilter={setcustomFilter} />
                                </Col>
                            </Row>
                        </Form.Group>

                    </Form>
                </Modal.Body>
            </Modal>
            </Row>
        );
    }, [filterText, downloadCSV]);

    function ExpandableExpander(props) {
        return expandedComponent(props);
    }

    const customStyles = {
        borderLeft: '1px solid #ddd',
        headCells: {
            style: {
                paddingLeft: cellPadding || '5px',
                paddingRight: cellPadding || '5px',
                borderRight: `${borderWidth || '1px'} solid #ddd`,
            },
        },
        cells: {
            style: {
                paddingLeft: cellPadding || '5px',
                paddingRight: cellPadding || '5px',
                borderRight: `${borderWidth || '1px'} solid #ddd`,
            },
        },
    };

    const scrollHeight =
        window.innerHeight < 350
            ? '450px'
            : window.innerHeight < 400
            ? '480px'
            : window.innerHeight < 450
            ? '510px'
            : '540px';
            
    return (
        <DataTable
            columns={columns}
            data={showSearchClear || customfilter?.organization ? filteredItems : dataList}
            defaultSortFieldID={1}
            noDataComponent={
                showSearchClear
                    ? filteredItems.length > 0
                        ? ''
                        : 'No data found'
                    : dataList.length > 0
                    ? ''
                    : 'No data found'
            }
            pagination={pagination !== false}
            selectableRowsComponent={BootyCheckbox}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={10}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            progressPending={pending}
            progressComponent={<Loader />}
            responsive
            highlightOnHover
            expandableRows={!!expandableRows}
            expandableRowsHideExpander={!expandableRows}
            expandableRowsComponent={expandableRows && ExpandableExpander}
            expandOnRowClicked={!!expandableRows}
            expandableRowExpanded={() => !!expandableRows}
            onRowExpandToggled={() => !!expandableRows}
            conditionalRowStyles={conditionalRowStyles && conditionalRowStyles}
            fixedHeader
            fixedHeaderScrollHeight={scrollHeight}
            customStyles={customStyles}
        />
    );
}
