/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';
import { useD3Report } from './ContextAPI';
import DataTable from './DataTable';

export default function ShowModel() {
    const { show, setShow, data } = useD3Report();
    const axiosPrivate = useAxiosPrivate();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [partner, setPartner] = useState();

    useEffect(async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllPartnerUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setPartner(response?.data?.partner_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Partner Data Not Found!');
            }
        }
    }, []);

    useEffect(() => {
        setPageLength(data?.d3_records?.length);
    }, [data.d3_records]);

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () =>
        data?.d3_records?.map((item) => (
            <Tab key={item.id} eventKey={item.id} title={item.name}>
                <DataTable
                    medicinData={item.drug_list}
                    partner={partner}
                    dateOrMonthRange={
                        data.month && data.year
                            ? `${
                                  data?.month == 1
                                      ? 'January'
                                      : data?.month == 2
                                      ? 'February'
                                      : data?.month == 3
                                      ? 'March'
                                      : data?.month == 4
                                      ? 'April'
                                      : data?.month == 5
                                      ? 'May'
                                      : data?.month == 6
                                      ? 'June'
                                      : data?.month == 7
                                      ? 'July'
                                      : data?.month == 8
                                      ? 'August'
                                      : data?.month == 9
                                      ? 'September'
                                      : data?.month == 10
                                      ? 'October'
                                      : data?.month == 11
                                      ? 'November'
                                      : data?.month == 12
                                      ? 'December'
                                      : ''
                              } - ${data.year}`
                            : `${moment(data.start_date).format('DD MMM YYYY')} - ${moment(
                                  data.end_date
                              ).format('DD MMM YYYY')}`
                    }
                />
            </Tab>
        ));

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>D3 Consumption Reports</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 1}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className="btnSuccess"
                            disabled={page === pageLength}
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                        >
                            Next
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
