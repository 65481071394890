/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Receive DataTable
 * Description: Receive DataTable
 * Date: 05/06/2022
 */

import { toArray } from 'lodash';
import { Form, Table } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useDispensaryStockReceive } from './ContextAPI';

export default function ReceiveTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useDispensaryStockReceive();

    console.log({ receiveData });

    const receiveRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.received_qty = value;
        itemdata.rejected_qty = item.approved_supply_qty - value;
        itemdata.rejected_reason = receiveData[itemdata.facility_stockout_id].rejected_reason;
        // itemdata.batch_no = receiveData[itemdata.facility_stockout_id].batch_no;

        const list = receiveData;
        list[itemdata.facility_stockout_id] = itemdata;

        setReceiveData(list);
        setReceiveList(toArray(list));
    };

    const rejectRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.received_qty = item.approved_supply_qty - value;
        itemdata.rejected_qty = value;
        itemdata.rejected_reason = receiveData[itemdata.facility_stockout_id].rejected_reason;
        // itemdata.batch_no = receiveData[itemdata.facility_stockout_id].batch_no;

        const list = receiveData;
        list[itemdata.facility_stockout_id] = itemdata;

        setReceiveData(list);
        setReceiveList(toArray(list));
    };

    const rejectReasonRowHandler = (value, item) => {
        const itemdata = item;

        itemdata.received_qty = receiveData[itemdata.facility_stockout_id].received_qty;
        itemdata.rejected_qty = receiveData[itemdata.facility_stockout_id].rejected_qty;
        itemdata.rejected_reason = value;
        // itemdata.batch_no = receiveData[itemdata.facility_stockout_id].batch_no;

        const list = receiveData;
        list[itemdata.facility_stockout_id] = itemdata;

        setReceiveData(list);
        setReceiveList(toArray(list));
    };

    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            width: '550px',
            wrap: true,
            cell: (row) => (
                <h6 className="my-2" style={{ fontSize: '13px' }}>
                    Name: {row.drug_name}
                </h6>
            ),
        },
        {
            selector: (row) => row.requested_qty,
            sortable: true,
            width: '200px',
            cell: (row) => (
                <h6 className="my-2" style={{ fontSize: '13px' }}>
                    Receive Qty:{' '}
                    <span className={`${row?.stockout_details?.length === 0 && 'text-danger'}`}>
                        {row?.stockout_details?.reduce(
                            (total, item) => total + Number(item.approved_supply_qty),
                            0
                        )}
                    </span>
                </h6>
            ),
        },
    ];

    const expandedComponent = (props) => (
        <Table style={{ fontSize: '12px' }}>
            <thead>
                <tr>
                    <th>Batch No.</th>
                    <th>Mfg Date</th>
                    <th>Exp Date</th>
                    <th>Supplied Quantity</th>
                    <th>Receive Quantity</th>
                    <th>Reject Quantity</th>
                    <th>Reject Reason</th>
                </tr>
            </thead>
            <tbody style={{ width: '100%' }}>
                {props.data.stockout_details?.map((el) => (
                    <tr key={Math.random()}>
                        <td width="150px">{el.batch_no}</td>
                        <td width="150px">{el.mfg_date}</td>
                        <td width="150px">{el.expire_date}</td>
                        <td width="150px">{el.approved_supply_qty}</td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="number"
                                onBlur={(e) => {
                                    receiveRowHandler(e.target.value, el);
                                }}
                                defaultValue={receiveData?.[el.facility_stockout_id]?.received_qty}
                            />
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="number"
                                onBlur={(e) => {
                                    rejectRowHandler(e.target.value, el);
                                }}
                                defaultValue={receiveData?.[el.facility_stockout_id]?.rejected_qty}
                            />
                        </td>
                        <td>
                            <Form.Control
                                size="sm"
                                type="text"
                                onBlur={(e) => {
                                    rejectReasonRowHandler(e.target.value, el);
                                }}
                                defaultValue={
                                    receiveData?.[el.facility_stockout_id]?.rejected_reason
                                }
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    const conditionalRowStyles = [
        {
            when: (row) => row.drug_name,
            style: {
                backgroundColor: '#ebebeb',
            },
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };

    return useDataTable({
        columns,
        data,
        search_column,
        pagination: false,
        expandableRows: true,
        expandedComponent,
        conditionalRowStyles,
    });
}
